import queryString from 'query-string'
import { storeToRefs } from 'pinia'
import { useLobbyGameApi } from '@/api/lobby-game'
import { EMPTY_CONTENT, SORT } from '~~/constants/lobby'
import { useGameStore } from '~~/store/game'
import { useLoading } from '~/composables/useLoading'
import { IItemCongGame, IProvider, ISortItem } from '~/types/lobby.type'
export const useGame = () => {
  const { $device, $pinia, $axios } = useNuxtApp()
  const { LOBBY_CATEGORY, LIST_LOBBY_GAME } = useLobbyGameApi()
  const store = useGameStore($pinia)
  const route = useRoute()
  const { loading, load, isLoading } = useLoading()
  const { providerGames } = storeToRefs(store)
  const listGame = ref<IItemCongGame[]>([])
  const listProvider = ref<IProvider[]>([])
  const allObject = ref<IProvider>({
    code: 'all',
    name: 'Tất cả',
    url: '',
    image_path: ''
  })
  const currentProvider = ref<IProvider | null>(allObject.value)
  const currentSort = ref<ISortItem>(SORT[0] as ISortItem)
  const isShowCategoryProvider = ref<boolean>(true)
  const totalItemPerPage = ref<number>(0)
  const totalItem = ref<number>(0)
  const totalItemLobby = ref<number>(0)
  const isShowSort = ref<boolean>(false)
  const loadMoreLoading = ref<boolean>(true)
  const placeholderCount = ref<number>(0)
  const totalGameLobby = ref<number>(0)
  const listCongGame = ref<IItemCongGame[]>([])

  const limit = 30
  const page = ref<number>(1)
  const isLoadingMore = ref(false)
  const hasMoreGame = ref(false)
  const totalPage = ref(0)

  const providerMapping = {
    go: 'hit'
  } as Record<string, string>
  const reversedMapping = Object.fromEntries(
    Object.entries(providerMapping).map(([key, value]) => [value, key])
  )

  const initDataGame = async () => {
    page.value = 1
    if (getItemBySort(route.query.sort as string)) {
      currentSort.value = getItemBySort(route.query.sort as string) ?? SORT[0]
      return await fetchListGameSort(currentSort.value)
    } else {
      currentSort.value = SORT[0]
      if (route.params.type) {
        return await fetchListGame()
      }
    }
    return []
  }

  const setDataGame = (data: {
    totalItem: number
    totalItemPerPage: number
    listGame: any[]
    totalItemLobby: number
    providerGames: any[]
  }) => {
    totalItem.value = data.totalItem
    totalItemPerPage.value = data.totalItemPerPage
    listGame.value = data.listGame
    totalItemLobby.value = data.totalItemLobby
    isShowSort.value = data.totalItemLobby >= 10
    store.setProviderGames(data.providerGames)
    if (getItemBySort(route.query.sort as string)) {
      currentSort.value = getItemBySort(route.query.sort as string) ?? SORT[0]
    } else {
      currentSort.value = SORT[0]
    }
    if (providerGames.value) {
      filterProvider(providerGames.value)
      currentProvider.value = listProvider.value.find((item: IProvider) => item?.code === route.query.provider || providerMapping[item.code] === route.query.provider) || allObject.value
    }
  }

  const initData = async () => {
    loadMoreLoading.value = true
    const gameData = await initDataGame()
    setDataGame({ ...gameData, providerGames: providerGames.value })
  }

  function getItemBySort(value: string): ISortItem | undefined {
    return SORT.find((obj) => obj.typeUrl === value && obj.typeUrl !== '')
  }

  const handleFilterProvider = (item: IProvider) => {
    page.value = 1
    loadMoreLoading.value = true
    currentProvider.value = item
    let providerType = providerMapping?.[item?.code] ?? item?.code
    providerType = reversedMapping[providerType] || providerType
    const _query = { ...route.query }
    if (item.code === 'all') {
      delete _query.provider
    } else {
      _query.provider = providerType
    }
    navigateTo({ query: _query })
    setTimeout(() => {
      handleSort()
    }, 0)
  }

  const handleSort = (item?: ISortItem) => {
    page.value = 1
    loadMoreLoading.value = true
    if (item) {
      currentSort.value = item
    }
    const _query = { ...route.query }
    if (currentSort.value.type && Number(currentSort.value.type) !== 0 && currentSort?.value?.type !== 'default') {
      fetchListGameSort(item)
      if (currentSort?.value?.typeUrl) {
        _query.sort = currentSort.value.typeUrl
      } else {
        delete _query.sort
      }
    } else {
      fetchListGame()
      if (currentSort?.value?.typeUrl) {
        _query.sort = currentSort.value.typeUrl
      } else {
        delete _query.sort
      }
    }
    if (currentProvider.value?.code === 'all') {
      delete _query.provider
    } else {
      const providerType = providerMapping[currentProvider.value?.code] || currentProvider.value?.code
      _query.provider = providerType
    }
    navigateTo({ query: _query })
  }
  const currentGameType = (): string => {
    const newPath = route.path.replace(/-/g, '_')
    const parts = newPath.split('/')
    let gameType = 'all'
    if (parts.length > 0) {
      gameType = parts[parts.length - 1]
    }
    return gameType
  }
  const filterProvider = (data: IItemCongGame[]) => {
    const type = route?.params?.type
    const filteredItem = data.find((item: IItemCongGame) => item?.alias === type)
    listProvider.value = filteredItem?.providers || []
    const isAllExists = listProvider.value.some((item: IProvider) => item?.code === 'all')
    if (!isAllExists) {
      listProvider.value.unshift(allObject.value)
    }
    if (listProvider.value.length > 2) {
      isShowCategoryProvider.value = true
    } else {
      isShowCategoryProvider.value = false
    }
  }
  const getBackgroundFromPath = (): string => {
    return 'defaultBackground'
  }

  const fetchGames = (queryUrl: string) => {
    return $axios.get(`${LIST_LOBBY_GAME}?${queryUrl}`)
  }

  const fetchListGame = async () => {
    const gameType = route?.params?.type
    loading()
    const _query = { ...route.query }
    let partnerProvider = typeof _query?.provider === 'string' ? _query.provider.toLowerCase() : ''
    partnerProvider = reversedMapping[partnerProvider] || partnerProvider
    let queryUrl = ''
    if (partnerProvider && partnerProvider !== 'all') {
      queryUrl = queryString.stringify({
        path_alias: gameType,
        provider_code: partnerProvider,
        limit,
        page: page.value
      })
    } else {
      queryUrl = queryString.stringify({
        path_alias: gameType,
        limit,
        page: page.value
      })
    }

    try {
      const { data: response } = await fetchGames(queryUrl)
      if (response.status === 'OK') {
        if (page.value > 1) {
          listGame.value = listGame.value.concat(response.data?.items)
        } else {
          listGame.value = response.data?.items
        }
        totalItem.value = response.data?.total
        totalItemLobby.value = response.data?.total_game_lobby
        totalItemPerPage.value = limit * (page.value - 1) + response.data?.items.length
        totalPage.value = response.data.total_page
        hasMoreGame.value = page.value < response.data.total_page
        store.setGameLists(listGame.value)
        if (totalItemLobby.value < 10) {
          isShowSort.value = false
        } else {
          isShowSort.value = true
        }
        return {
          totalItem: totalItem.value,
          totalItemPerPage: totalItemPerPage.value,
          listGame: listGame.value,
          totalItemLobby: totalItemLobby.value,
          isShowSort: isShowSort.value
        }
      }
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
    }
    load()
  }
  const handleChangeCategoryMenu = (item: IItemCongGame) => {
    if (item?.alias || item?.link) {
      navigateTo(item?.alias ? item.alias : item.link)
    }
    currentProvider.value = allObject.value
  }
  const fetchListGameSort = async (params: { type?: string } = {}) => {
    loading()
    const _query = { ...route.query }
    const gameType = route?.params?.type
    let partnerProvider = typeof _query?.provider === 'string' ? _query.provider.toLowerCase() : ''
    partnerProvider = reversedMapping[partnerProvider] || partnerProvider
    const sortItem = getItemBySort(_query.sort as string)
    const sort = params?.type || (sortItem ? sortItem.type : 'default')
    let queryUrl = ''
    queryUrl = queryString.stringify({
      path_alias: gameType,
      provider_code: partnerProvider && partnerProvider !== 'all' ? partnerProvider : '',
      sort_by: sort,
      limit,
      page: page.value
    })
    try {
      const { data: response } = await fetchGames(queryUrl)
      if (response.status === 'OK') {
        if (page.value > 1) {
          listGame.value = listGame.value.concat(response.data?.items)
        } else {
          listGame.value = response.data?.items
        }
        totalItem.value = response.data?.total || 0
        totalItemLobby.value = response.data?.total_game_lobby
        totalItemPerPage.value = limit * (page.value - 1) + response.data?.items.length
        totalPage.value = response.data.total_page
        hasMoreGame.value = page.value < response.data.total_page
        store.setGameLists(listGame.value)
        if (totalItemLobby.value < 10) {
          isShowSort.value = false
        } else {
          isShowSort.value = true
        }
        return {
          totalItem: totalItem.value,
          totalItemPerPage: totalItemPerPage.value,
          listGame: listGame.value,
          totalItemLobby: totalItemLobby.value,
          isShowSort: isShowSort.value
        }
      }
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
      return {
        totalItem: totalItem.value,
        totalItemPerPage: totalItemPerPage.value,
        listGame: listGame.value,
        totalItemLobby: totalItemLobby.value,
        isShowSort: isShowSort.value
      }
    } finally {
      load()
    }
  }

  const fetchListGameJackpot = async () => {
    try {
      const { data: response } = await $axios.get(`${LIST_LOBBY_GAME}?${'limit=25&path_alias=no-hu'}`)
      if (response.status === 'OK') {
        listGame.value = response.data?.items
        totalItemLobby.value = response.data?.total_game_lobby

        totalItem.value = response.data?.total || 0
        totalItemPerPage.value = limit * (page.value - 1) + response.data?.items.length
        isShowSort.value = true
        return response.data?.items
      }
      return []
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
      return []
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const calculatePlaceholder = (itemsCount: number) => {
    let itemsPerRow
    if ($device.isDesktop) {
      itemsPerRow = 5
    } else {
      itemsPerRow = 3
    }

    const remainder = itemsCount % itemsPerRow
    placeholderCount.value = remainder === 0 ? 0 : itemsPerRow - remainder
    return placeholderCount.value
  }
  const fetchCongGame = async () => {
    loading()
    try {
      const { data: response } = await $axios.get(`${LOBBY_CATEGORY}?alias=game`)
      if (response.status === 'OK') {
        const listGame = response?.data || []
        store.setProviderGames(listGame)
        filterProvider(listGame)
      }
      return response?.data || []
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
      return []
    } finally {
      load()
    }
  }
  onMounted(() => {
    calculatePlaceholder()
  })

  watch(listGame, () => {
    calculatePlaceholder()
  })

  const loadMoreListGame = async () => {
    try {
      isLoadingMore.value = true
      page.value = page.value + 1
      if (route.query.sort) {
        await fetchListGameSort({})
      } else {
        await fetchListGame()
      }
      isLoadingMore.value = false
    } catch (error) {
      console.log(error)
    }
  }

  return {
    scrollToTop,
    handleFilterProvider,
    initData,
    listProvider,
    currentProvider,
    isShowCategoryProvider,
    listGame,
    isLoading,
    totalItem,
    totalItemPerPage,
    loadMoreLoading,
    EMPTY_CONTENT,
    SORT,
    currentSort,
    handleSort,
    isShowSort,
    calculatePlaceholder,
    currentGameType,
    placeholderCount,
    fetchListGameJackpot,
    getBackgroundFromPath,
    fetchCongGame,
    listCongGame,
    fetchGames,
    handleChangeCategoryMenu,
    totalGameLobby,
    reversedMapping,
    providerMapping,
    limit,
    isLoadingMore,
    page,
    initDataGame,
    setDataGame,
    totalItemLobby,
    loadMoreListGame
  }
}
